const Cena = ({ imePaketa, cena, usluge }) => {
    console.log(imePaketa)
    return (
        <div className=" bg-white shadow-lg rounded-lg overflow-hidden min-w-[20vw] flex flex-col justify-between">
            <div className="bg-red-500 text-white text-center p-2 lg:p-3">
                 <h2 className="text-2xl font-bold mb-2 text-cardTitleMob lg:text-cardTitle">{imePaketa}</h2>
            </div>
            <div className="p-6 text-center">
                <ul className="mb-6">
                    {usluge.map(usluga => {
                        return <li className="mb-2 text-black text-cardFontMob lg:text-cardFont">✔ {usluga}</li>

                    })}
                </ul>
            </div>
            <div className="bg-red-500 text-white text-center p-1 lg:p-2">
                 <h2 className="font-bold mb-2 text-cardTitleMob lg:text-cardTitle">{cena}e</h2>
            </div>
      </div>
    )
}

export default Cena;