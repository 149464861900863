export const cene = async () => {
  return {
    photo: {
      vencanja: [
        {
            imePaketa: 'Basic Moment',
            usluge: [
              'Glavni i pomoćni fotograf po potrebi', 
              'Shooting na dan venčanja', 
              'Fotografije na flesh memoriji', 
              'Online foto galerija'
            ],
            cena: 100
        },
        {
          imePaketa: 'Classic Capture',
          usluge: [
            'Glavni i pomoćni fotograf', 
            'Shooting na dan venčanja', 
            'Fotografije na flesh memoriji (dve kopije)', 
            'Kutija za flesh memorije' ,
            'Album sa 200 fotografija', 
            'Online foto galerija'
          ],
          cena: 250
        },
        {
          imePaketa: 'Premium Portrait',
          usluge: [
            'Glavni i pomoćni fotograf', 
            'Reportažni fotograf',
            'Prewedding',
            'Shooting na dan venčanja', 
            'Book veličine 20x30cm',
            'Slika na platnu veličine 60x90cm',
            'Fotografije na flesh memoriji (4 kopije)', 
            'Kutija za flesh memorije' ,
            'Album sa 300 fotografija', 
            'Online foto galerija'
          ],
          cena: 600
        }
      ],
      ostalo: [
        {
          imePaketa: 'Ceo dan',
          usluge: [
            'Glavni i pomoćni fotograf po potrebi', 
            'Porodicni photoshooting', 
            'Fotografije na flesh memoriji', 
            'Online foto galerija'
          ],
          cena: 100
      },
      {
        imePaketa: 'Samo crkva',
        usluge: [
          'Glavni fotograf', 
          'Porodicni photoshooting', 
          'Fotografije na flesh memoriji', 
          'Online foto galerija'
        ],
        cena: 80
      },
      {
        imePaketa: 'Punoletstvo',
        usluge: [
          'Glavni fotograf i pomoćni po potrebi', 
          'Porodicni photoshooting', 
          'Fotografije na flesh memoriji', 
          'Online foto galerija'
        ],
        cena: 100
      }
      ]
    },
      video: {
        vencanja: [
          {
              imePaketa: 'Basic Moment',
              usluge: [
                'Jedna kamera', 
                'Bez ograničavanja snimka', 
                'Kreativni snimak u dužini jedne pesme', 
                'Snimak na flesh memoriji sa imenima',
                'Full HD'
              ],
              cena: 300
          },
          {
            imePaketa: 'Classic Capture',
            usluge: [
              'Jedna kamera', 
              'Bez ograničavanja snimka', 
              'Kreativni snimak u dužini jedne pesme', 
              'Snimak na flesh memoriji sa imenima',
              '4K'
            ],
            cena: 400
          },
          {
            imePaketa: 'Premium Portrait',
            usluge: [
              'Dve kamere', 
              'Bez ograničavanja snimka', 
              'Kreativni snimak u dužini jedne pesme', 
              'Snimanje u Logu (Najviši nivo kvaliteta)', 
              'Dron',
              'Snimak na flesh memoriji sa imenima (3 kopije)',
              '4K'
            ],
            cena: 700
          }
        ],
        ostalo: [
          {
            imePaketa: 'Basic Moments',
            usluge: [
              'Jedna kamera', 
              'Bez ograničavanja snimka', 
              'Snimak na flesh memoriji.',
              'Full HD'
            ],
            cena: 150
        },
        {
          imePaketa: 'Classic Capture',
          usluge: [
            'Jedna kamera', 
            'Bez ograničavanja snimka', 
            'Kreativni snimak u dužini jedne pesme', 
            'Snimak na flesh memoriji sa kutijom',
            'Full HD'
          ],
          cena: 200
        },
        {
          imePaketa: 'Premium Portrait',
          usluge: [
            'Jedna kamera', 
            'Bez ograničavanja snimka', 
            'Kreativni snimak u dužini jedne pesme', 
            'Snimak na flesh memoriji sa kutijom (2 kopije)',
            '4K'
          ],
          cena: 300
        }
        ]
      },
      cene: {
        fotka: 50,
        books: {
          small: 80,
          medium: 120,
          big: 150,
          large: 200
        },
        slikaNaPlatnu: {
          small: 50,
          medium: 90,
          large: 150
        },
        dron: 70,
        log: 100,
        put: 20
      }
  }
}