import { useEffect, useState } from "react";
import MainBody from "./MainBody";

function App() {
  const [loading, isLoading] = useState(true);


  useEffect(() => {
    setTimeout(() => {
      isLoading(false);
    }, 1600);
  }, []);

  return (
    <>
      {!loading ? (
        <MainBody />
      ) : (
        <div className="flex items-center justify-center h-screen transform lg:-translate-y-0 -translate-y-16">
          <div className="animate-spin rounded-full border-t-4 border-white border-solid h-32 w-32"></div>
        </div>
      )}
    </>
  );
}

export default App;
