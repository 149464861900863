import { useState, useEffect } from "react";
import Cena from "./Cena";

import { cene as ceneAsync } from "./cene.js";

const Cenovnik = ({ isOpen, onClose }) => {
  const [activeTab, setActiveTab] = useState('photo');
  const [cene, setCene] = useState();

  useEffect(() => {
    ceneAsync().then(data => {
      setCene(data)
    })
  }, [])


  if (!isOpen) return null;
  console.log(cene)

  return (
    <div className="absolute inset-0  flex justify-center items-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-[90vw] h-[90vh] overflow-y-auto scrollbar-hide relative pt-20 pb-20">
        <h2 className="font-semibold mb-4 text-black">Cenovnik</h2>

        <div className="max-w-lg mx-auto">
          {/* Tab Buttons */}
          <div className="flex border-b border-gray-300">
            <button
              className={`w-1/2 py-2 text-center ${activeTab === 'photo' ? 'border-b-4 border-red-500 text-red-500' : 'text-gray-600'
                }`}
              onClick={() => setActiveTab('photo')}
            >
              Photo
            </button>
            <button
              className={`w-1/2 py-2 text-center ${activeTab === 'video' ? 'border-b-4 border-red-500 text-red-500' : 'text-gray-600'
                }`}
              onClick={() => setActiveTab('video')}
            >
              Video
            </button>
          </div>

          {/* Tab Content */}
          <div className="p-1 lg:p-6 bg-white">
            {/* PHOTO */}
            {activeTab === 'photo' && (
              <>
                <h1 className="mb-3 lg:mb-6 text-mainTitleMob lg:text-mainTitle text-gray-700 mt-2">Venčanja</h1>
                <div className="flex flex-col lg:flex-row gap-8 justify-center">
                  {cene.photo.vencanja.map(({ imePaketa, usluge, cena }) => {
                    return <Cena imePaketa={imePaketa} usluge={usluge} cena={cena} />
                  })}
                </div>
                <div className="flex flex-row justify-center">
                  <h1 className="mb-8 mt-8 text-mainTitleMob lg:text-mainTitle text-gray-700 block text-nowrap">Krštenja / Rođendani / Punoletstva</h1>
                </div>

                <div className="flex flex-col lg:flex-row gap-8 justify-center">
                  {cene.photo.ostalo.map(({ imePaketa, usluge, cena }) => {
                    return <Cena imePaketa={imePaketa} usluge={usluge} cena={cena} />
                  })}
                </div>
              </>
            )}
            <div className="flex flex-col justify-center">
            </div>
            {/* PHOTO */}
            {/* VIDEO */}
            {activeTab === 'video' && (
              <>
                <h1 className="mb-3 text-mainTitleMob lg:text-mainTitle text-gray-700 mt-2">Venčanja</h1>
                <div className="flex flex-col lg:flex-row gap-8 justify-center">
                  {cene.video.vencanja.map(({ imePaketa, usluge, cena }) => {
                    return <Cena imePaketa={imePaketa} usluge={usluge} cena={cena} />
                  })}
                </div>
                <div className="flex flex-row justify-center">
                  <h1 className="mb-8 mt-8 text-mainTitleMob lg:text-mainTitle text-gray-700 block text-nowrap">Krštenja / Rođendani / Punoletstva</h1>
                </div>
                <div className="flex flex-col lg:flex-row gap-8 justify-center">
                  {cene.video.ostalo.map(({ imePaketa, usluge, cena }) => {
                    return <Cena imePaketa={imePaketa} usluge={usluge} cena={cena} />
                  })}
                </div>
              </>
            )}
            {/* VIDEO */}
          </div>

        </div>
        <div className="text-black text-left text-wrap text-fusNotaMob lg:text-fusNota mt-5 lg:min-w-[1080px] ">
            <p>Cena izrade po fotografiji veličine 13x18cm je <span className="text-red-400">{cene.cene.fotka}</span> dinara.</p>
            <p>Cene izrade photobooka:</p>
            <p>15x20cm - <span className="text-red-400">{cene.cene.books.small}</span>e, 20x30cm - <span className="text-red-400">{cene.cene.books.medium}</span>e, 30x30cm - <span className="text-red-400">{cene.cene.books.big}</span>e, 30x40cm - <span className="text-red-400">{cene.cene.books.large}</span>e</p>
            <p>Cene su za klasičan book na canvasu, ukoliko je izrada od druge vrste materijala, pošaljite upit za cenu.</p>
            <p>Cene štampe na platnu:</p>
            <p>40x60cm - <span className="text-red-400">{cene.cene.slikaNaPlatnu.small}</span>e, 60x90cm - <span className="text-red-400">{cene.cene.slikaNaPlatnu.medium}</span>e, 100x150cm - <span className="text-red-400">{cene.cene.slikaNaPlatnu.large}</span>e</p>
            <p>Reportažna fotografija podrazumeva beleženje autentičnih, spontanih trenutaka kroz ceo dan, sa ciljem da ispriča pricu i prenese atmosferu događaja na vizuelno upečatljiv nacin.</p>
            <br />
            <p>U paketima Basic Moments i Classic Capture kao i u sekciji Krštenja/Rođendani/Punoletstva dron nije uračunat i dodatno se naplaćuje po ceni od <span className="text-red-400">{cene.cene.dron}</span>e.</p>
            <p>Doplata za snimanje u Logu (Najvišem nivou kvaliteta) van Premium Portrait paketa je <span className="text-red-400">{cene.cene.log}</span>e.</p>
            <p>U navedene cene su uračunati troškovi puta do 50km, preko toga se dodatno naplaćuje <span className="text-red-400">{cene.cene.put}</span> centi po 1km.</p>
          </div>
        <div className="absolute top-5 right-5">
          <button
            onClick={onClose}
            className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600 "
          >
            X
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cenovnik;